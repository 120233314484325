<template>
  <div class="overflow-auto">
    <b-pagination-nav
      v-model="currentPageNumber"
      :limit="1000"
      :link-gen="linkGen"
      :page-gen="pageGen"
      :number-of-pages="numberOfPages"
      hide-goto-end-buttons
      hide-ellipsis
    />
  </div>
</template>

<script>
import { BPaginationNav } from 'bootstrap-vue'
import px from 'vue-types'

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

export default {
  name: 'AlphabetNav',
  model: {
    prop: 'value',
    event: 'update',
  },
  components: {
    BPaginationNav,
  },
  props: {
    value: px.string,
  },
  computed: {
    currentPageNumber: {
      get() {
        if (!this.value) return null
        const index = ALPHABET.indexOf(this.value)
        if (index === -1) return null
        return index + 1
      },
      set(page) {
        this.$emit('update', ALPHABET.charAt(page - 1))
      },
    },
    numberOfPages() {
      return ALPHABET.length
    },
  },
  methods: {
    linkGen() {
      return null
    },
    pageGen(page) {
      return ALPHABET.charAt(page - 1)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .page-item {
    &.active {
      border: 1px solid $primary;
    }
  }
}
</style>
