<template>
  <ApiListController :filter="{ letter }" :fetch="fetch" name="Teams" no-pagination>
    <template #default="{ items }">
      <TeamBookmarkController :items="items" />
    </template>
  </ApiListController>
</template>

<script>
import px from 'vue-types'

import { getEsportTeamsAutocomplete } from '../../api/autocomplete.js'

import ApiListController from './ApiListController.vue'
import TeamBookmarkController from './bookmark/Team.vue'

export default {
  name: 'CatalogTeamsController',
  components: {
    ApiListController,
    TeamBookmarkController,
  },
  props: {
    letter: px.string.isRequired,
  },
  methods: {
    async fetch(params, config) {
      const data = await getEsportTeamsAutocomplete(params.letter, config)
      return { data, total: data.length }
    },
  },
}
</script>

<style scoped></style>
