<template>
  <AppTable ref="table" :fields="fields" :items="items" primary-key="id">
    <template #cell(bookmark)="data">
      <BookmarkToggle :value="hasBookmark(data.item.id)" @update="setBookmark(data.item.id, $event)" />
    </template>

    <template #cell(team)="data">
      <TeamCell link v-bind="data.item" />
    </template>

    <template #cell(matches)="data">
      <LoadTeamOverviewCell :id="data.item.id">
        <template #default="{ overview }">
          {{ overview | sumMatches }}
        </template>
      </LoadTeamOverviewCell>
    </template>

    <template #cell(wins)="data">
      <LoadTeamOverviewCell :id="data.item.id">
        <template #default="{ overview }">
          {{ overview | sumWins }}
        </template>
      </LoadTeamOverviewCell>
    </template>

    <template #cell(loses)="data">
      <LoadTeamOverviewCell :id="data.item.id">
        <template #default="{ overview }">
          {{ overview | sumLoses }}
        </template>
      </LoadTeamOverviewCell>
    </template>

    <template #cell(roundsWonPerMatch)> TBA </template>
    <template #cell(roundsLostPerMatch)> TBA </template>
  </AppTable>
</template>

<script>
import px from 'vue-types'

import BookmarkToggle from '../generic/BookmarkToggle.vue'
import AppTable from '../generic/Table.vue'
import TeamCell from '../Table/cells/TeamCell.vue'

import LoadTeamOverviewCell from './cells/LoadTeamOverviewCell.vue'

const sumMatches = overview => overview?.maps?.reduce((sum, { picks }) => sum + picks, 0)
const sumWins = overview => overview?.maps?.reduce((sum, { wins }) => sum + wins, 0)
const sumLoses = overview => sumMatches(overview) - sumWins(overview)

const formatNan = number => (Number.isNaN(number) ? 'N/A' : number)

export default {
  name: 'TeamsTable',
  components: { BookmarkToggle, LoadTeamOverviewCell, TeamCell, AppTable },
  props: {
    bookmarks: px.objectOf(px.bool),
    items: px.arrayOf(
      px.shape({
        id: px.string.isRequired,
        name: px.string.isRequired,
        image: px.string,
      }).loose
    ).isRequired,
  },
  computed: {
    fields() {
      return [
        { key: 'bookmark', label: 'Bookmark' },
        { key: 'team', label: 'Team' },
        {
          key: 'total_matches_played',
          label: 'Total number of matches',
          formatter: value => (value != null ? value : 'N/A'),
        },
        {
          key: 'total_matches_won',
          label: 'Total number of wins',
          formatter: (value, key, item) => (item.total_matches_played != null ? value || 0 : 'N/A'),
        },
        {
          key: 'total_matches_lost',
          label: 'Total number of loses',
          formatter: (value, key, item) =>
            item.total_matches_played != null
              ? formatNan((item.total_matches_played || 0) - (item.total_matches_won || 0))
              : 'N/A',
        },
        {
          key: 'rounds_win_ratio',
          label: 'Average won rounds / match',
          formatter: (value, key, item) => formatNan(Math.round(item.total_rounds_won / item.total_matches_played)),
        },
        {
          key: 'rounds_loss_ratio',
          label: 'Average lost rounds / match',
          formatter: (value, key, item) =>
            formatNan(Math.round((item.total_rounds_played - item.total_rounds_won) / item.total_matches_played)),
        },
      ]
    },
  },
  filters: { sumMatches, sumWins, sumLoses },
  methods: {
    hasBookmark(id) {
      return this.bookmarks?.[id]
    },
    setBookmark(id, value) {
      this.$emit('set:bookmark', { id, value })
    },
  },
}
</script>

<style scoped></style>
