<template>
  <div>
    <LoadingCell v-if="loading" />
    <BIconExclamationCircle v-else-if="error" class="text-danger" />
    <slot v-else v-bind="{ overview }" />
  </div>
</template>

<script>
import { BIconExclamationCircle } from 'bootstrap-vue'
import px from 'vue-types'

import { getTeamOverview } from '../../../api/teams.js'
import axios from '../../../axios.js'
import LoadingCell from '../../Table/cells/LoadingCell.vue'

export default {
  name: 'LoadTeamOverviewCell',
  components: { BIconExclamationCircle, LoadingCell },
  props: {
    id: px.string.isRequired,
  },
  data: () => ({
    error: null,
    loading: true,
    overview: null,
  }),
  watch: {
    id: {
      immediate: true,
      handler(teamId) {
        this.load(teamId)
      },
    },
  },
  beforeDestroy() {
    this.cancelRequest()
  },
  methods: {
    cancelRequest() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
        this.cancelTokenSource = null
      }
    },
    async load(teamId) {
      this.error = null
      this.overview = null
      this.loading = true
      try {
        this.cancelRequest()
        this.cancelTokenSource = axios.CancelToken.source()
        const data = await getTeamOverview(teamId, {
          cancelToken: this.cancelTokenSource.token,
        })
        this.overview = Object.freeze(data)
      } catch (e) {
        if (axios.isCancel(e)) {
          return
        }
        this.error = e
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
