<template>
  <div>
    <PageTitle>Teams</PageTitle>

    <AppTabs>
      <AppTab lazy title="Search">
        <form @submit.prevent="onSelect(null)">
          <div class="d-flex flex-row flex-nowrap">
            <AppAutosuggest
              ref="autosuggest"
              :input-props="inputProps"
              :should-render-suggestions="shouldRenderSuggestions"
              :section-configs="sectionConfigs"
              :suggestions="sectionedSuggestions"
              :value="focused ? search : search || inputProps.placeholder"
              @input="onInput"
              @selected="onSelect"
              @focus="onFocus"
              @blur="onBlur"
            >
              <template #before-suggestions>
                <Loading v-if="loading" />
                <ErrorAlert v-else-if="error">
                  {{ error }}
                </ErrorAlert>
                <InfoAlert v-else-if="search.length < 2"> Enter at least 2 characters to get results </InfoAlert>
                <InfoAlert v-else-if="!suggestions.length"> No matching teams </InfoAlert>
              </template>
              <template #default="{ suggestion }">
                <TeamCell v-bind="suggestion.item" link variant="row" />
              </template>
            </AppAutosuggest>

            <b-form-group>
              <b-button type="submit" variant="warning" :disabled="showResults && loading">
                <span v-if="showResults && loading">Loading...</span>
                <span v-else>Search</span>
              </b-button>

              <b-button type="reset" variant="outline-warning" @click.prevent="onInput('')"> Reset </b-button>
            </b-form-group>
          </div>
        </form>

        <div v-if="showResults">
          <TeamSearchController :filter="searchFilter" :table="bookmarkController" />
        </div>
      </AppTab>

      <AppTab lazy title="Bookmarked">
        <BookmarkedTeamsController />
      </AppTab>

      <AppTab lazy title="All (A-Z)">
        <AlphabetNav v-model="alphaNav" />

        <CatalogTeamsController v-if="alphaNav" :letter="alphaNav" />
      </AppTab>
    </AppTabs>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import px from 'vue-types'

import { getEsportTeamsAutocomplete } from '../api/autocomplete.js'
import axios from '../axios.js'
import TeamBookmarkController from '../components/controllers/bookmark/Team.vue'
import BookmarkedTeamsController from '../components/controllers/BookmarkedTeams.vue'
import CatalogTeamsController from '../components/controllers/CatalogTeams.vue'
import TeamSearchController from '../components/controllers/search/Team.vue'
import AlphabetNav from '../components/generic/AlphabetNav.vue'
import AppAutosuggest from '../components/generic/Autosuggest.vue'
import ErrorAlert from '../components/generic/ErrorAlert.vue'
import InfoAlert from '../components/generic/InfoAlert.vue'
import Loading from '../components/generic/Loading.vue'
import PageTitle from '../components/generic/PageTitle.vue'
import AppTab from '../components/generic/Tab.vue'
import AppTabs from '../components/generic/Tabs.vue'
import TeamCell from '../components/Table/cells/TeamCell.vue'
import { DEFAULT_ITEMS_PER_PAGE } from '../store/modules/search.js'

export default {
  name: 'Teams',
  components: {
    InfoAlert,
    ErrorAlert,
    Loading,
    CatalogTeamsController,
    BookmarkedTeamsController,
    TeamSearchController,
    AlphabetNav,
    AppTab,
    AppTabs,
    TeamCell,
    AppAutosuggest,
    PageTitle,
  },
  props: {
    s: px.string,
  },
  data: () => ({
    alphaNav: null,
    error: null,
    focused: false,
    loading: false,
    perPage: DEFAULT_ITEMS_PER_PAGE,
    search: '',
    showResults: false,
    suggestions: [],
    teamName: '',
  }),
  computed: {
    bookmarkController() {
      return TeamBookmarkController
    },
    inputProps() {
      return {
        class: 'form-control',
        id: 'team-search-filter',
        ref: 'input',
        placeholder: 'Search for a team',
      }
    },
    searchFilter() {
      return Object.freeze({
        name: this.search,
      })
    },

    sectionConfigs() {
      return {
        default: {},
        empty: { type: 'div' },
        error: { type: 'div' },
        loading: { type: 'div' },
      }
    },

    sectionedSuggestions() {
      if (this.loading) return [{ name: 'loading', data: [{ loading: true }] }]
      if (this.error) return [{ name: 'error', data: [{ error: this.error }] }]
      if (!this.suggestions.length) return [{ name: 'empty', data: [{ empty: true }] }]
      return [{ data: this.suggestions }]
    },
  },
  watch: {
    s: {
      immediate: true,
      handler(val) {
        this.onInput(val)
        this.onSelect(null)
      },
    },
  },
  beforeDestroy() {
    this.cancelRequest()
  },
  methods: {
    cancelRequest() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
        this.cancelTokenSource = null
      }
    },
    shouldRenderSuggestions() {
      return this.focused
    },
    async onInput(search) {
      this.search = search
      this.showResults = false
      this.cancelRequest()
      this.suggestions = []
      this.error = null
      if (search.length < 2) return
      this.loading = true
      try {
        this.cancelTokenSource = axios.CancelToken.source()
        const data = await getEsportTeamsAutocomplete(search, {
          cancelToken: this.cancelTokenSource.token,
        })
        this.suggestions = Object.freeze((data || []).map(item => Object.freeze(item)))
      } catch (e) {
        if (axios.isCancel(e)) {
          return
        }
        this.error = axios.extractErrorMessage(e)
        console.error(this.error, e)
        Sentry.captureException(e)
      } finally {
        this.loading = false
      }
    },
    onSelect(selection) {
      if (selection) {
        this.$router.push(`/team/${selection.item.id}`)
      } else {
        if (this.search !== this.s) {
          this.$router.push({ query: { s: this.search } })
        }
        this.showResults = this.search.length > 0
        this.$refs.autosuggest?.$refs.autosuggest?.$refs.input.blur()
      }
    },
    onFocus() {
      this.focused = true
    },
    onBlur() {
      this.focused = false
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .alert {
    margin: 0;
    border-radius: 0;
  }
}
</style>
