<template>
  <div>
    <slot v-bind="context">
      <component
        :is="tableComponent"
        :bookmarks="bookmarks"
        @set:bookmark="setBookmark"
        :items="items"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </slot>
  </div>
</template>

<script>
import px from 'vue-types'
import { mapActions } from 'vuex'

import { pxNullable } from '../../Map/types.js'
import TeamsTable from '../../tables/TeamsTable.vue'

export default {
  name: 'TeamBookmarkController',
  props: {
    items: px.arrayOf(
      px.shape({
        is_bookmarked: px.bool,
      }).loose
    ).isRequired,
    table: pxNullable(px.object).def(null),
  },
  data: () => ({
    bookmarks: null,
  }),
  computed: {
    context() {
      return {
        bookmarks: this.bookmarks,
        setBookmark: this.setBookmark,
        ...this.$attrs,
        $listeners: this.$listeners,
      }
    },
    tableComponent() {
      return this.table || TeamsTable
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(val) {
        this.bookmarks = Object.fromEntries(val.map(item => [item.id, item.is_bookmarked]))
      },
    },
  },
  methods: {
    ...mapActions({
      setBookmarkAction: 'bookmarks/set',
    }),
    isBookmarked(id) {
      return this.bookmarks[id]
    },
    setBookmark({ item, value = !this.isBookmarked(item.id) }) {
      this.bookmarks[item.id] = value
      return this.setBookmarkAction({ type: 'team', id: value ? item.id : item.bookmark, value })
    },
  },
}
</script>

<style scoped></style>
