<template>
  <ApiLoadingController ref="ctrl" :fetch="fetch" :params="fetchParams">
    <template #default="{ data }">
      <PaginationController
        v-if="!noPagination"
        :current-page.sync="currentPage"
        :name="name"
        :per-page.sync="perPageValue"
        :total-rows="data.total"
      >
        <slot :items="freeze(data.data)" :total="emitStatus(data.total)" />
      </PaginationController>
      <slot v-else :items="freeze(data.data)" :total="emitStatus(data.total)" />
    </template>
    <template #no-data>
      <slot #no-data> No data </slot>
    </template>
  </ApiLoadingController>
</template>

<script>
import px from 'vue-types'

import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE_INDEX } from '../../store/modules/search.js'
import { pxNullable } from '../Map/types.js'

import ApiLoadingController from './ApiLoadingController.vue'
import PaginationController from './PaginationController.vue'

export default {
  name: 'ApiListController',
  components: {
    ApiLoadingController,
    PaginationController,
  },
  props: {
    fetch: px.func.isRequired,
    filter: px.object,
    name: pxNullable(px.string).def(null),
    noPagination: px.bool.def(false),
    perPage: pxNullable(px.integer).def(null),
  },
  data: () => ({
    currentPage: DEFAULT_PAGE_INDEX,
    localPerPage: DEFAULT_ITEMS_PER_PAGE,
  }),
  computed: {
    fetchParams() {
      return Object.freeze({
        ...this.filter,
        count: this.perPageValue,
        index: this.currentPage,
      })
    },
    perPageValue: {
      get() {
        return this.perPage != null ? this.perPage : this.localPerPage
      },
      set(value) {
        this.localPerPage = value
        this.$emit('update:per-page', value)
      },
    },
  },
  watch: {
    filter() {
      this.currentPage = DEFAULT_PAGE_INDEX
    },
  },
  methods: {
    emitStatus(total) {
      this.$el.dispatchEvent(new CustomEvent('status', { detail: `(${total})`, bubbles: true }))
      return total
    },
    freeze(obj) {
      return Object.freeze(obj)
    },
    retry() {
      this.$refs.ctrl.load()
    },
    reset() {
      this.currentPage = DEFAULT_PAGE_INDEX
    },
  },
}
</script>
