<template>
  <ApiListController :fetch="fetch" name="Teams">
    <template #default="{ items }">
      <TeamBookmarkController :items="items" />
    </template>
  </ApiListController>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ApiListController from './ApiListController.vue'
import TeamBookmarkController from './bookmark/Team.vue'

export default {
  name: 'BookmarkedTeamsController',
  components: {
    ApiListController,
    TeamBookmarkController,
  },
  computed: {
    ...mapGetters({
      globalBookmarksCount: 'bookmarks/countTeams',
    }),
  },
  watch: {
    globalBookmarksCount(value) {
      this.$el.dispatchEvent(new CustomEvent('status', { detail: `(${value})`, bubbles: true }))
    },
  },
  methods: {
    ...mapActions({
      listBookmarks: 'bookmarks/list',
    }),
    fetch(params, config) {
      return this.listBookmarks({
        ...config,
        ...params,
        type: 'team',
      })
    },
  },
}
</script>
