<template>
  <label class="per-page">
    <div class="per-page--label">{{ label }}</div>
    <BDropdown :text="`${value}`">
      <DropdownItem
        v-for="option in options"
        :key="option"
        :selected="option === value"
        @click.native="$emit('change', option)"
      >
        {{ option }}
      </DropdownItem>
    </BDropdown>
  </label>
</template>

<script>
import { BDropdown } from 'bootstrap-vue'
import px from 'vue-types'

import { DEFAULT_ITEMS_PER_PAGE } from '../../store/modules/search.js'
import DropdownItem from '../Form/DropdownItem.vue'

const PAGINATION_OPTIONS = Object.freeze([10, 25, 50])

export default {
  name: 'PerPage',
  components: {
    BDropdown,
    DropdownItem,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    name: px.string.def('Items'),
    value: px.oneOf(PAGINATION_OPTIONS).def(DEFAULT_ITEMS_PER_PAGE),
  },
  computed: {
    label() {
      return `${this.name} per page`
    },
    options() {
      return PAGINATION_OPTIONS
    },
  },
  methods: {
    parseInt: val => parseInt(val, 10),
  },
}
</script>

<style lang="scss" scoped>
.per-page {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &--label {
    flex: 0 0 auto;
    margin-right: 0.5em;
  }
}
</style>
