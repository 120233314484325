<template>
  <ApiListController :fetch="fetch" :filter="fetchParams" name="Teams">
    <template #default="{ items }">
      <component :is="tableComponent" :items="items" />
    </template>
  </ApiListController>
</template>

<script>
import px from 'vue-types'

import { findTeams } from '../../../api/search.js'
import { pxNullable } from '../../Map/types.js'
import TeamsTable from '../../tables/TeamsTable.vue'
import ApiListController from '../ApiListController.vue'

export default {
  name: 'TeamSearchController',
  components: { ApiListController },
  props: {
    filter: px.object,
    table: pxNullable(px.object).def(null),
  },
  computed: {
    fetchParams() {
      return this.filter
    },
    tableComponent() {
      return this.table || TeamsTable
    },
  },
  methods: {
    async fetch(params, config) {
      return await findTeams(params, config)
    },
  },
}
</script>

<style scoped></style>
