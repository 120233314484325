<template>
  <h1 class="page-title mb-4">
    <slot>{{ title }}</slot>
    <BookmarkToggle v-if="bookmarkable" class="bookmark-toggle" v-model="internalBookmark" />
  </h1>
</template>

<script>
import px from 'vue-types'

import BookmarkToggle from './BookmarkToggle.vue'

export default {
  name: 'PageTitle',
  components: {
    BookmarkToggle,
  },
  model: {
    prop: 'bookmark',
    event: 'update:bookmark',
  },
  props: {
    bookmark: px.bool.def(false),
    bookmarkable: px.bool.def(false),
    title: px.string,
  },
  computed: {
    internalBookmark: {
      get() {
        return this.bookmark
      },
      set(value) {
        this.$emit('update:bookmark', value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.page-title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .bookmark-toggle {
    align-self: flex-start;
  }
}
</style>
