<template>
  <div class="pwp">
    <AppPagination
      :value="currentPage"
      :per-page="perPage"
      :total-rows="totalRows"
      @input="$emit('update:current-page', $event)"
    />
    <PerPage :name="name" :value="perPage" @change="$emit('update:per-page', $event)" />
  </div>
</template>

<script>
import px from 'vue-types'

import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE_INDEX } from '../../store/modules/search.js'

import AppPagination from './Pagination.vue'
import PerPage from './PerPage.vue'

export default {
  name: 'PaginationWithPaging',
  components: { PerPage, AppPagination },
  props: {
    currentPage: px.integer.def(DEFAULT_PAGE_INDEX),
    name: px.string,
    perPage: px.integer.def(DEFAULT_ITEMS_PER_PAGE),
    totalRows: px.integer,
  },
}
</script>

<style lang="scss" scoped>
.pwp {
  min-width: $min-content-width;
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  & > * {
    margin-bottom: 0;
  }
}
</style>
