<template>
  <BPagination ref="pagination" first-number last-number v-bind="$attrs" v-on="$listeners">
    <template #prev-text>
      <BButton>« Prev</BButton>
    </template>
    <template #next-text>
      <BButton>Next »</BButton>
    </template>
    <template #page="{ page, active }">
      <BButton :active="active" :class="{ active }">
        {{ page }}
      </BButton>
    </template>

    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </BPagination>
</template>

<script>
import { BButton, BPagination } from 'bootstrap-vue'

export default {
  name: 'AppPagination',
  components: { BButton, BPagination },
}
</script>

<style lang="scss" scoped></style>
