<template>
  <div>
    <slot name="top-pagination" v-bind="context">
      <PaginationWithPaging
        :current-page.sync="currentPageValue"
        :name="name"
        :per-page.sync="perPageValue"
        :total-rows="totalRows"
      />
    </slot>
    <slot v-bind="context">
      <component :is="childComponent" v-bind="childAttributes" v-on="childListeners" />
    </slot>
    <slot name="bottom-pagination" v-bind="context">
      <PaginationWithPaging
        :current-page.sync="currentPageValue"
        :name="name"
        :per-page.sync="perPageValue"
        :total-rows="totalRows"
      />
    </slot>
  </div>
</template>

<script>
import px from 'vue-types'

import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE_INDEX } from '../../store/modules/search.js'
import PaginationWithPaging from '../generic/PaginationWithPaging.vue'
import { pxNullable } from '../Map/types.js'

export default {
  name: 'PaginationController',
  components: { PaginationWithPaging },
  props: {
    child: pxNullable(px.object).def(null),
    currentPage: pxNullable(px.integer).def(null),
    name: px.string.def('Item'),
    perPage: pxNullable(px.integer.def(DEFAULT_ITEMS_PER_PAGE)).def(null),
    totalRows: pxNullable(px.integer).def(null),
  },
  data: () => ({
    localCurrentPage: DEFAULT_PAGE_INDEX,
    localPerPage: DEFAULT_ITEMS_PER_PAGE,
  }),
  computed: {
    childAttributes() {
      return Object.freeze({
        currentPage: this.currentPageValue,
        name: this.name,
        perPage: this.perPageValue,
        totalRows: this.totalRows,
        ...this.$attrs,
      })
    },
    childComponent() {
      return this.child || 'div'
    },
    childListeners() {
      return Object.freeze({
        ...this.$listeners,
        'update:current-page': this.updateCurrentPage,
        'update:per-page': this.updatePerPage,
      })
    },

    context() {
      return Object.freeze({
        attributes: this.childAttributes,
        listeners: this.childListeners,
      })
    },

    currentPageValue: {
      get() {
        return this.currentPage != null ? this.currentPage : this.localCurrentPage
      },
      set(value) {
        this.localCurrentPage = value
        this.$emit('update:current-page', value)
      },
    },

    perPageValue: {
      get() {
        return this.perPage != null ? this.perPage : this.localPerPage
      },
      set(value) {
        this.localPerPage = value
        this.$emit('update:per-page', value)
        // restart from first page
        this.currentPageValue = DEFAULT_PAGE_INDEX
      },
    },
  },
  methods: {
    updateCurrentPage(value) {
      this.currentPageValue = value
    },
    updatePerPage(value) {
      this.perPageValue = value
    },
  },
}
</script>

<style scoped></style>
